import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: 'AIzaSyDs7TmaPCPknc4WI64pRXLCtOBXKaSRLLU',
  authDomain: 'schole-com.firebaseapp.com',
  projectId: 'schole-com',
  storageBucket: 'schole-com.appspot.com',
  messagingSenderId: '505787234785',
  appId: '1:505787234785:web:28572757df66fe2800baa0'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };