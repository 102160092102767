import React, { useEffect } from 'react';

function MainApp() {
  useEffect(() => {
    window.location.replace('http://34.65.0.28:8502');
  }, []);

  return <div>Redirecting...</div>;
}

export default MainApp;