// Login.js (exact original)
import React, { useState, useEffect } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from './firebase';
import 'tailwindcss/tailwind.css';

function Login() {
  const [showBanner, setShowBanner] = useState(false);
  const [typingText, setTypingText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const fullText = 'AI Assistant for Your Learning Journey';

  useEffect(() => {
    const accepted = localStorage.getItem('cookiesAccepted');
    if (!accepted) setShowBanner(true);
    let i = 0;
    const interval = setInterval(() => {
      setTypingText(fullText.substring(0, i));
      i++;
      if (i > fullText.length) clearInterval(interval);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  const handleGoogleLogin = async () => {
    if (!termsAccepted) {
      alert('Please accept the terms to continue.');
      return;
    }
    setLoading(true);
    try {
      await signInWithPopup(auth, provider);
      window.location.replace('http://34.65.0.28:8502');
    } catch (error) {
      console.error('❌ Google login error:', error);
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  const doNothing = () => {};

  return (
    <div className="bg-gradient-to-br from-indigo-100 via-white to-blue-50 min-h-screen flex items-center justify-center">
      <div className="bg-white shadow-2xl rounded-3xl p-10 w-[90%] max-w-md text-center animate-fade-in">
        <h1 className="text-4xl font-bold text-gray-800 mb-2">👋 Welcome Back</h1>
        <p className="text-sm text-gray-500 mb-6 h-5">{typingText}</p>
        <label className="flex items-center justify-center text-sm mb-6">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            className="form-checkbox h-4 w-4 text-blue-600 mr-2"
          />
          <span>
            I accept the{' '}
            <a href="https://www.schole.ai/terms-of-services" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">
              Terms
            </a>{' '}
            &{' '}
            <a href="https://www.schole.ai/privacy-policy" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">
              Privacy
            </a>
          </span>
        </label>
        <div className="flex flex-col space-y-3 mb-2">
          <button
            onClick={handleGoogleLogin}
            disabled={!termsAccepted || loading}
            className={`w-full py-2 px-4 rounded-xl font-semibold transition duration-300 ${
              termsAccepted
                ? 'bg-gradient-to-r from-blue-500 to-indigo-500 text-white hover:opacity-90'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {loading ? '🔄 Logging in...' : '🔓 Login with Google'}
          </button>
          {['💼 Microsoft', '🐱 GitHub', '💼 LinkedIn', '📱 SMS', '🔑 Facebook'].map((label, i) => (
            <button
              key={i}
              onClick={doNothing}
              disabled={!termsAccepted}
              className={`w-full py-2 px-4 rounded-xl font-medium transition duration-300 ${
                termsAccepted
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-200 text-gray-400 cursor-not-allowed'
              }`}
            >
              Login with {label}
            </button>
          ))}
        </div>
        {loginError && <p className="text-red-500 text-sm mt-2">❌ Login failed. Please try again.</p>}
      </div>
      {showBanner && (
        <div className="fixed bottom-0 w-full bg-gray-900 text-white text-sm text-center py-3 px-6 z-50">
          🍪 We use cookies to improve your experience.
          <button
            onClick={handleAcceptCookies}
            className="ml-4 bg-green-500 hover:bg-green-600 text-white font-medium px-3 py-1 rounded"
          >
            Accept
          </button>
        </div>
      )}
    </div>
  );
}

export default Login;